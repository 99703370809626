<template>
<div class="shopping-cart-container" tabindex="0" v-on:keyup.enter="toggle">
    <div class="shopping-cart-label">
        <span class="fa-layers fa-fw">
            <div
                class="icon icon-padding ti-btn"
                v-on:click="btnClick"
                v-on:keydown.enter="btnClick"
            >
                <span
                    class="icon fa-duotone fa-shopping-cart"
                />
                <span
                    v-if="icon === 'fa-shopping-cart' && cartCounterNumber" class="fa-layers-counter fa-layers-bottom-right"
                    :class="{'large-quantity': cartCounterNumber > 8}"
                >
                    <span v-if="cartCounterNumber <= 8">
                        {{ cartCounterNumber }}
                    </span>
                    <span v-else>
                        8+
                    </span>
                </span>
            </div>
        </span>
    </div>
</div>
</template>

<script lang="ts">

import { defineComponent, toRaw } from 'vue'
import { ShoppingCart } from '$services/shopify'

export default defineComponent({
    props: {
        text: String,
        icon: String,
        duoToneIcon: Boolean,
        iconOnly: Boolean
    },

    emits: ['menuSelect'],

    computed: {
        cartCounterNumber() {
            return toRaw(ShoppingCart.getQty())
        }
    },
    methods: {
        btnClick(_event: MouseEvent | KeyboardEvent) {
            this.toggle()
        },

        toggle() {
            ShoppingCart.toggle()
        }

    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.shopping-cart-container {
    cursor: pointer;
    color: var(--neutral-50);
    position: relative;
    z-index: 1;

    .dropdown-label {
        display: inline-block;
        margin: 4px 0;
        line-height: 0;
        vertical-align: middle;
    }

    .dropdown-label:hover {
        border-radius: 4px;
        background-color: var(--hover-tab);
    }

    .icon {
        width: 22.5px;
        height: 20px;
        &:hover {
            color: var(--primary-200);
        }
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        padding: 12px 16px;
        background-color: var(--surface-400);
        border-radius: 16px 0px 16px 16px !important;
        min-width: 240px;
        top: 100%;
    }

    .item:hover {
        background-color: var(--surface-50);
        border-radius: inherit;
    }

        // shopping cart icon with counter
        .fa-layers {
        position: unset;

        .fa-layers-counter {
            background: var(--primary-200);
            color: var(--surface-900);
            font-size: 44px;
            font-weight: 900;

            &.fa-layers-bottom-right {
                bottom: -4px;
                right: 8px;
            }

            &.large-quantity {
                font-size: 40px;
                padding: 16px 10px;
            }

            &.open {
                background: var(--neutral-50);
            }
        }
    }
}
</style>
