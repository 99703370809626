<template>
    <!--Presentation managed by `Zendesk.install(..)` below ..-->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Zendesk } from '$services/zendesk'

export default defineComponent({
    props: {
        zdkey: {
            type: String,
            default: import.meta.env.JBG_ZENDESK_KEY,
            required: false
        }
    },
    mounted() {
        if (this.zdkey) {
            Zendesk.install(this.zdkey)
        }
    }
})
</script>
