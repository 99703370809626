<template>
<div class="modal">
    <section class="modal-body-container">
        <p v-if="!!options?.header" class="modal-header">{{ options?.header }}<br></p>
        <p class="modal-body">{{ options?.text }}</p>
        <div class="flex-row flex-end">
            <ButtonElement
                class="action"
                v-on:click="takeAction" v-on:keydown.enter="takeAction"
            >
                {{ options?.buttonText }}
            </ButtonElement>
        </div>
    </section>
    <div
        class="close" role="button" :aria-label="$t('WIDGETS.CLOSE')"
        tabindex="0"
        v-on:click="closeModal" v-on:keydown.enter="closeModal"
    >
        <i class="fa-solid fa-xmark" />
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OptionsModal from '$plugins/modal/Modal.vue'
import ButtonElement from './ButtonElement.vue'

export default defineComponent({
    name: 'Modal',
    components: {
        ButtonElement
    },
    props: {
        options: {
            type: OptionsModal.Options,
            required: true
        }
    },
    emits: ['action'],
    methods: {
        closeModal() {
            this.$hideModal()
        },

        takeAction() {
            this.$emit('action', this.options.value)
        }
    }
})
</script>

<style lang="scss" scoped>
    .modal {
        background: var(--surface-400);
        height: auto;
        margin-top: 166px;
        max-width: 400px;
        top: 0;
        width: calc(100% - 32px);

        .action {
            background: var(--primary-200);
            color: var(--neutral-900);
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            padding: 13px 16px;
        }

        .close {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;

            svg {
                width: 28px;
                height: 28px;
            }
        }

        .modal-body-container{
            .modal-header {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 16px;
            }

            .modal-body {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 24px;
            }
        }

        .flex-end {
            justify-content: flex-end;
        }
    }
</style>
